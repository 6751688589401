.background {
  height: 130vh;
  background-image: radial-gradient(var(--med-blue), var(--dark-blue));
  background-position: 0 -15rem;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  &__container {
    position: absolute;
    height: 30%;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-55%);

    &:hover {
    }
  }
}

.marquee {
  gap: 2rem;
}
