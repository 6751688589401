.button {
  z-index: 1;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    border: 4px double var(--highlight);
    z-index: 3;
    position: relative;
    width: fit-content;
    padding: 1rem 2rem;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: var(--highlight);

      & > .button__text {
        color: #161932;
      }

      & > .button__arrow {
        animation: bounce 1s infinite linear;
        filter: invert(10%) sepia(10%) saturate(3052%) hue-rotate(196deg) brightness(94%) contrast(99%);
      }
    }
  }

  &__text {
    color: var(--text);
    font-weight: 600;
    font-family: var(--font);
    font-size: 1.6rem;
    z-index: 3;
    position: relative;
  }

  &__arrow {
    z-index: 3;
    position: relative;
    filter: invert(91%) sepia(8%) saturate(467%) hue-rotate(197deg) brightness(89%) contrast(90%);
    width: 3rem;
  }
}

@keyframes bounce {
  0% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(-5px);
  }
}
