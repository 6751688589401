.projects {
  height: 70vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  &__phone {
    height: 90%;
    width: 20%;
    // height: 60rem;
    // width: 35rem;
    background-color: var(--text);
    border-radius: 20px;
    position: absolute;
    box-shadow: var(--box-shadow);
    left: 15%;
    top: -20rem;
    z-index: 3;
    padding: 1rem;
    transition: all 1s;

    @media only screen and (max-width: 1300px) {
      width: 30%;
    }

    @media only screen and (max-width: 1000px) {
      width: 35%;
    }

    @media only screen and (max-width: 800px) {
      display: none;
    }
  }

  &__pc {
    height: 90%;
    width: 60%;
    // height: 60rem;
    // width: 80rem;
    background-color: var(--text);
    border-radius: 20px;
    position: absolute;
    box-shadow: var(--box-shadow);
    right: 10%;
    top: -10rem;
    padding: 1rem;
    z-index: 2;
    transition: all 1s;

    @media only screen and (max-width: 800px) {
      width: 80%;
    }

    @media only screen and (max-width: 500px) {
      width: 95%;
      right: 2.5%;
    }
  }

  &__iframe {
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }

  &__background {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--dark-blue);
    height: 40%;
    width: 100%;
    clip-path: polygon(0 85%, 100% 0, 100% 100%, 0% 100%);
  }

  &__navigation {
    display: flex;
    align-items: center;
    position: absolute;
    top: -20rem;
    right: 20%;
    z-index: 10;
    transition: all 2s;

    &::after {
      content: "";
      height: 0.5rem;
      width: 30rem;
      background-color: var(--highlight);
      position: relative;
      order: 2;
      margin: 0 1rem;

      @media only screen and (max-width: 1300px) {
        width: 15rem;
      }
    }

    @media only screen and (max-width: 1000px) {
      right: 10%;
    }

    @media only screen and (max-width: 800px) {
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__button {
    border: none;
    border: 4px double var(--highlight);
    background-color: var(--med-blue);
    cursor: pointer;
    order: 1;
    transition: all 0.2s;
    &:last-child {
      order: 3;
    }

    &:hover {
      background-color: var(--highlight);

      & > .projects__button-icon {
        filter: invert(10%) sepia(10%) saturate(3052%) hue-rotate(196deg) brightness(94%) contrast(99%);
      }
    }
  }

  &__button-icon {
    filter: invert(93%) sepia(8%) saturate(874%) hue-rotate(194deg) brightness(89%) contrast(90%);
    pointer-events: none;
  }

  &__page-button {
    position: relative;
    margin-bottom: 5rem;
    left: 0%;
    top: auto;
    transform: none;
    transition: all 2s;
  }
}

.fly-in-left {
  left: -100%;
}
.fly-in-right {
  right: -100%;
}
