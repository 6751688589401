@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;500;700&display=swap");

:root {
  --light-blue: rgb(44, 50, 101);
  --med-blue: rgb(46, 52, 105);
  --dark-blue: #161932;

  --highlight: #ff7f50;

  --text: #c8cada;

  --box-shadow: 2px 5px 10px 2px rgba(0, 0, 0, 0.5);

  --font: "Kumbh Sans", sans-serif;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;

  @media only screen and (max-width: 600px) {
    font-size: 55%;
  }
}

body {
  box-sizing: border-box;
  font-family: var(--font);
  background-color: var(--text);
  overflow-x: clip;

  
}

a,
a:visited,
a:link {
  color: currentColor;
}

/*!! Scrollbar */
@supports (overflow-y: overlay) {
  html {
    overflow-y: overlay;
  }
}

*::-webkit-scrollbar {
  display: block;
  width: 1.4rem;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-track {
  background-color: #00000000;
}

*::-webkit-scrollbar-track-piece {
  background-color: #00000000;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--highlight);
  border-radius: 24px;
}
