.contact {
  height: 100vh;
  background-color: var(--dark-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1rem;
  position: relative;

  &__form {
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: var(--dark-blue);
    padding: 4rem 2rem;

    @media only screen and (max-width: 800px) {
      width: 90%;
    }
  }

  &__input {
    background-color: transparent;
    border: none;
    border-bottom: 1px double var(--highlight);
    color: var(--text);
    font-size: 1.7rem;
    margin-bottom: 3rem;
    font-style: var(--font);
    padding: 1rem;
    transition: all 0.3s;

    &:active,
    &:focus {
      outline: none;
    }
  }

  &__label {
    color: var(--text);
    font-size: 1.7rem;
    margin-bottom: 1rem;
  }

  &__button {
    align-self: center;
  }

  &__footer {
    height: 5%;
    width: 100vw;
    background-color: var(--text);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    align-items: flex-end;
    @media only screen and (max-width: 800px) {
      flex-direction: column;
      align-items: center;
      height: 10%;
      justify-content: flex-end;
    }
  }

  &__footer-img-container {
    position: absolute;
    left: 50%;
    bottom: 50%;
    background-color: var(--highlight);
    border-radius: 50%;
    height: 7rem;
    width: 7rem;
    z-index: 10;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: var(--dark-blue);
      border: 4px double var(--highlight);
    }

    @media only screen and (max-width: 800px) {
      bottom: 70%;
    }
  }

  &__footer-img {
    filter: invert(10%) sepia(10%) saturate(3052%) hue-rotate(196deg) brightness(94%) contrast(99%);

    &:hover {
      filter: invert(78%) sepia(64%) saturate(4180%) hue-rotate(325deg) brightness(100%) contrast(102%);
    }
  }

  &__footer-text {
    color: var(--dark-blue);
  }
}
