.background-item {
  position: relative;
  display: inline-block;
  color: var(--light-blue);
  font-family: sans-serif;
  font-weight: bold;
  font-size: 2rem;
  height: min-content;
  transition: all 0.2s;
  cursor: default;
  &:hover {
    color: var(--highlight);
  }
}
