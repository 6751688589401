.about {
  height: 100vh;
  display: flex;
  background-color: var(--dark-blue);
  margin-top: -1rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10rem;

  @media only screen and (max-width: 800px) {
    bottom: 70%;
    height: 120vh;
  }

  &__container {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 800px) {
      flex-direction: column;
      
    }
    
  }

  &__img {
    width: 30%;

    @media only screen and (max-width: 800px) {
      align-self: flex-start;
      width: 50%;
    }
  }

  &__text {
    color: var(--text);
    font-size: 1.6rem;
    background-color: var(--med-blue);
    padding: 2rem;
    box-shadow: var(--box-shadow);
  }

  &__page-button {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
}
